import { UpdateParcelBodyType } from '@shipping-aggregator/shared-types';
import {
  ADD_PARCEL,
  DISABLE_MANAGE_PARCEL,
  ENABLE_MANAGE_PARCEL,
  HIDE_SCANNER,
  RESET_CURRENT_PARCEL,
  RESET_PARCELS,
  RESET_STATE,
  SET_CURRENT_PARCEL,
  SHOW_SCANNER
} from '../types';
import { ContrAgentType, CurrentParcelType, updateParcelsState } from './scanner-state';

type ActionType = {
  type: string;
  payload?:
    | UpdateParcelBodyType
    | { id: string; parcelId: string; contragent: { id: string; name: string } };
};

export type InitialScannerState = {
  isDisabledManageParcel: boolean;
  isShowScanner: boolean;
  currentParcel: {
    id: string | null;
    parcelId: string | null;
    contragent: ContrAgentType;
  } | null;
  parcels: CurrentParcelType[];
};

export const scannerReducer = (
  state: InitialScannerState,
  action: ActionType
): InitialScannerState => {
  switch (action.type) {
    case SHOW_SCANNER:
      return {
        ...state,
        isShowScanner: true,
        isDisabledManageParcel: true,
        currentParcel: null
      };
    case HIDE_SCANNER:
      return {
        ...state,
        isShowScanner: false
      };
    case DISABLE_MANAGE_PARCEL:
      return {
        ...state,
        isDisabledManageParcel: true
      };
    case ENABLE_MANAGE_PARCEL:
      return {
        ...state,
        isDisabledManageParcel: false
      };
    case ADD_PARCEL: {
      let updatedParcels: CurrentParcelType[] = [...state.parcels];
      if (
        state.parcels.some(
          (p: CurrentParcelType) => p.parcelId === (action.payload as CurrentParcelType)?.parcelId
        )
      ) {
        updatedParcels = updateParcelsState(updatedParcels, action.payload as CurrentParcelType);
      } else {
        updatedParcels = [
          ...state.parcels,
          action.payload as CurrentParcelType
        ] as CurrentParcelType[];
      }

      return {
        ...state,
        isDisabledManageParcel: true,
        parcels: updatedParcels,
        currentParcel: null
      } as InitialScannerState;
    }
    case SET_CURRENT_PARCEL: {
      const { id, parcelId, contragent } = action.payload as CurrentParcelType;
      return {
        ...state,
        currentParcel: {
          id,
          parcelId,
          contragent
        }
      };
    }
    case RESET_CURRENT_PARCEL:
      return {
        ...state,
        currentParcel: null
      };
    case RESET_PARCELS:
      return {
        ...state,
        currentParcel: null,
        parcels: []
      };
    case RESET_STATE:
      return {
        isDisabledManageParcel: true,
        isShowScanner: true,
        currentParcel: null,
        parcels: []
      };
    default:
      return state;
  }
};
