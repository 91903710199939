import { ContragentShortType } from '@shipping-aggregator/shared-types';
import { isEqual } from 'lodash';
import { FC, useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Status } from '../constatns';

export type FormFilterDataType = {
  search: string;
  partner: string;
  statuses: Partial<Record<Status, boolean>>;
};

type FormFilterPickupPointProps = {
  isLoadingForm: boolean;
  listsContragents: ContragentShortType[];
  onFiltersChanged: (formFilterData: FormFilterDataType, hasChanges: boolean) => void;
};

const defaultValues: FormFilterDataType = {
  search: '',
  partner: '',
  statuses: {
    [Status.accepted]: true,
    [Status.expired]: true,
    [Status.refused]: true
  }
};

export const FormFilterPickupPoint: FC<FormFilterPickupPointProps> = ({
  onFiltersChanged,
  listsContragents,
  isLoadingForm
}) => {
  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<FormFilterDataType>({ defaultValues, mode: 'onChange' });

  const { search, statuses } = watch();

  const checkIfFormChanged = useCallback(() => {
    const currentValues = getValues();
    const hasChanges = !isEqual(currentValues, defaultValues);

    onFiltersChanged(currentValues, hasChanges);
  }, [getValues, onFiltersChanged]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (search.length >= 3 || search.length === 0) {
        checkIfFormChanged();
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [search, checkIfFormChanged]);

  const handleCheckboxChange = useCallback(
    (status: Status, checked: boolean) => {
      setValue('statuses', { ...statuses, [status]: checked }, { shouldDirty: true });
      checkIfFormChanged();
    },
    [statuses, setValue, checkIfFormChanged]
  );

  const renderStatusCheckbox = useCallback(
    (status: Status, label: string) => (
      <label key={status} className="flex items-center space-x-2">
        <input
          type="checkbox"
          className="checkbox checkbox-primary"
          checked={statuses[status]}
          onChange={(e) => handleCheckboxChange(status, e.target.checked)}
          disabled={isLoadingForm}
        />
        <span>{label}</span>
      </label>
    ),
    [statuses, isLoadingForm, handleCheckboxChange]
  );

  return (
    <form className="mt-6 space-y-4">
      <Controller
        name="search"
        control={control}
        rules={{
          validate: (value) =>
            value.length === 0 || value.length >= 3 || 'Введіть мінімум 3 символи'
        }}
        render={({ field }) => (
          <div>
            <input
              {...field}
              type="text"
              placeholder="Пошук"
              className="input input-bordered w-full"
              disabled={isLoadingForm}
            />
            {errors.search && (
              <p className="mt-1 text-sm text-orange-800">{errors.search.message}</p>
            )}
          </div>
        )}
      />
      <Controller
        name="partner"
        control={control}
        render={({ field }) => (
          <select
            {...field}
            className="select select-bordered w-full"
            onChange={(e) => {
              field.onChange(e.target.value);
              checkIfFormChanged();
            }}
            disabled={isLoadingForm}>
            <option value="">Обрати Партнера</option>
            {listsContragents.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        )}
      />
      <div className="space-y-2">
        {renderStatusCheckbox(Status.accepted, 'Прийнято')}
        {renderStatusCheckbox(Status.expired, 'Гість не прийшов')}
        {renderStatusCheckbox(Status.refused, 'Гість відмовився')}
      </div>
    </form>
  );
};
