import { Suspense } from 'react';
import * as ReactDOM from 'react-dom/client';
import 'webrtc-adapter';

import { callback, checkAuthCookies } from '@shipping-aggregator/frontend-auth';
import { RouterProvider } from 'react-router-dom';
import { ErrorMessage, Loading } from './app/components';
import ErrorBoundary from './app/components/error-boundary';
import { ScannerState } from './app/context/scanner/scanner-state';
import { router } from './routes';

function renderError(r: ReactDOM.Root): void {
  r.render(<ErrorMessage />);
}

function renderLoading(r: ReactDOM.Root): void {
  r.render(<Loading />);
}

function renderApp(r: ReactDOM.Root): void {
  r.render(
    <ScannerState>
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          <RouterProvider router={router} />
        </Suspense>
      </ErrorBoundary>
    </ScannerState>
  );
}

function getCode(): string | null {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');

  return code;
}

function createRoot(): ReactDOM.Root {
  return ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
}

const rootLoading = createRoot();
const code = getCode();

renderLoading(rootLoading);

if (code) {
  window.history.replaceState(null, '', window.location.pathname);
  callback(code).then(({ status }) => {
    rootLoading.unmount();
    if (status === 'ok') {
      renderApp(createRoot());
    } else {
      renderError(createRoot());
    }
  });
} else {
  checkAuthCookies().then((error) => {
    rootLoading.unmount();

    if (error) {
      renderError(createRoot());
    } else {
      renderApp(createRoot());
    }
  });
}
